import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskDocumentFragmentDoc } from './taskDocumentFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskDocumentFilesMutationVariables = Types.Exact<{
  mediaId: Types.Scalars['ID'];
  file: Types.Scalars['Upload'];
}>;

export type UpdateTaskDocumentFilesMutation = {
  __typename?: 'Mutation';
  updateTaskDocumentFiles: {
    __typename?: 'TaskDocumentsPaginator';
    data: Array<{
      __typename?: 'FileItem';
      id: string;
      name: string;
      file_name: string;
      original_file_name: string;
      url: string;
      url_download: string;
      url_thumb?: string | null;
      size: number;
      updated_at?: string | null;
      created_at?: string | null;
      is_extracting_pages: boolean;
      permissions: Array<Types.Permission>;
      url_pages?: Array<{ __typename?: 'FileItemPage'; page_index: number; url: string }> | null;
      custom_properties: {
        __typename?: 'FileItemCustomProperties';
        description?: string | null;
        is_cover?: boolean | null;
        visible?: boolean | null;
        number_of_pages?: number | null;
        export_pages?: Array<number> | null;
        extracted_pages?: Array<number> | null;
      };
      classification_tags: {
        __typename?: 'FileClassificationTagList';
        data: Array<{
          __typename?: 'FileClassificationTag';
          id: string;
          title: string;
          color: string;
          alias: string;
          type_id: Types.FileClassificationTagTypeId;
        }>;
      };
      links: Array<{
        __typename?: 'FileLink';
        file_id: string;
        linkable_type: string;
        linkable_id: string;
        file?: { __typename?: 'FileItem'; id: string; name: string } | null;
      }>;
    }>;
  };
};

export const UpdateTaskDocumentFilesDocument = gql`
  mutation UpdateTaskDocumentFiles($mediaId: ID!, $file: Upload!) {
    updateTaskDocumentFiles(files: [{ file_id: $mediaId, file: $file }]) {
      data {
        ...TaskDocument
      }
    }
  }
  ${TaskDocumentFragmentDoc}
`;

export function useUpdateTaskDocumentFilesMutation() {
  return Urql.useMutation<
    UpdateTaskDocumentFilesMutation,
    UpdateTaskDocumentFilesMutationVariables
  >(UpdateTaskDocumentFilesDocument);
}
