import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskProposalFragmentDoc } from './taskProposalFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskProposalItemsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.UpdateTaskProposalItemsInput;
}>;

export type UpdateTaskProposalItemsMutation = {
  __typename?: 'Mutation';
  updateTaskProposalItems: {
    __typename?: 'TaskProposal';
    id: string;
    task_id: string;
    external_proposal_id?: string | null;
    external_proposal_number?: string | null;
    external_proposal_url?: string | null;
    external_id?: number | null;
    date: string;
    state_id: Types.TaskProposalStateId;
    note?: string | null;
    reminder?: boolean | null;
    external_number: string;
    identifier: string;
    address: string;
    sum_gross: number;
    sum_net: number;
    sum_tax: number;
    url_preview: string;
    task: { __typename?: 'Task'; identifier?: string | null };
    state: { __typename?: 'TaskProposalState'; id: string; title: string; color: string };
    items: Array<{
      __typename?: 'TaskProposalItem';
      id: string;
      position: string;
      title: string;
      unit?: string | null;
      unit_price: number;
      amount: number;
      sum_net: number;
      sum_gross: number;
      tax: number;
    }>;
    file?: { __typename?: 'FileItem'; id: string; file_name: string; url: string } | null;
  };
};

export const UpdateTaskProposalItemsDocument = gql`
  mutation UpdateTaskProposalItems($id: ID!, $input: UpdateTaskProposalItemsInput!) {
    updateTaskProposalItems(id: $id, input: $input) {
      ...TaskProposal
    }
  }
  ${TaskProposalFragmentDoc}
`;

export function useUpdateTaskProposalItemsMutation() {
  return Urql.useMutation<
    UpdateTaskProposalItemsMutation,
    UpdateTaskProposalItemsMutationVariables
  >(UpdateTaskProposalItemsDocument);
}
