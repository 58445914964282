import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskImageFragmentDoc } from './taskImageFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskImageMutationVariables = Types.Exact<{
  file_id: Types.Scalars['ID'];
  description?: Types.InputMaybe<Types.Scalars['String']>;
  is_cover?: Types.InputMaybe<Types.Scalars['Boolean']>;
  visible?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type UpdateTaskImageMutation = {
  __typename?: 'Mutation';
  updateTaskFileProperties: Array<{
    __typename?: 'FileItem';
    id: string;
    name: string;
    file_name: string;
    original_file_name: string;
    url: string;
    url_thumb?: string | null;
    size: number;
    url_thumb_report?: string | null;
    url_download: string;
    is_extracting_pages: boolean;
    permissions: Array<Types.Permission>;
    custom_properties: {
      __typename?: 'FileItemCustomProperties';
      description?: string | null;
      is_cover?: boolean | null;
      visible?: boolean | null;
      number_of_pages?: number | null;
      export_pages?: Array<number> | null;
    };
    classification_tags: {
      __typename?: 'FileClassificationTagList';
      data: Array<{
        __typename?: 'FileClassificationTag';
        id: string;
        title: string;
        color: string;
        alias: string;
        type_id: Types.FileClassificationTagTypeId;
      }>;
    };
    links: Array<{
      __typename?: 'FileLink';
      file_id: string;
      linkable_type: string;
      linkable_id: string;
      file?: { __typename?: 'FileItem'; id: string; name: string } | null;
    }>;
  }>;
};

export const UpdateTaskImageDocument = gql`
  mutation UpdateTaskImage(
    $file_id: ID!
    $description: String
    $is_cover: Boolean
    $visible: Boolean
  ) {
    updateTaskFileProperties(
      files: [
        {
          file_id: $file_id
          properties: { description: $description, is_cover: $is_cover, visible: $visible }
        }
      ]
    ) {
      ...TaskImage
    }
  }
  ${TaskImageFragmentDoc}
`;

export function useUpdateTaskImageMutation() {
  return Urql.useMutation<UpdateTaskImageMutation, UpdateTaskImageMutationVariables>(
    UpdateTaskImageDocument,
  );
}
