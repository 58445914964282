import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type TaskProposalItemFragment = {
  __typename?: 'TaskProposalItem';
  id: string;
  position: string;
  title: string;
  unit?: string | null;
  unit_price: number;
  amount: number;
  sum_net: number;
  sum_gross: number;
  tax: number;
};

export type TaskProposalFragment = {
  __typename?: 'TaskProposal';
  id: string;
  task_id: string;
  external_proposal_id?: string | null;
  external_proposal_number?: string | null;
  external_proposal_url?: string | null;
  external_id?: number | null;
  date: string;
  state_id: Types.TaskProposalStateId;
  note?: string | null;
  reminder?: boolean | null;
  external_number: string;
  identifier: string;
  address: string;
  sum_gross: number;
  sum_net: number;
  sum_tax: number;
  url_preview: string;
  task: { __typename?: 'Task'; identifier?: string | null };
  state: { __typename?: 'TaskProposalState'; id: string; title: string; color: string };
  items: Array<{
    __typename?: 'TaskProposalItem';
    id: string;
    position: string;
    title: string;
    unit?: string | null;
    unit_price: number;
    amount: number;
    sum_net: number;
    sum_gross: number;
    tax: number;
  }>;
  file?: { __typename?: 'FileItem'; id: string; file_name: string; url: string } | null;
};

export const TaskProposalItemFragmentDoc = gql`
  fragment TaskProposalItem on TaskProposalItem {
    id
    position
    title
    unit
    unit_price
    amount
    sum_net
    sum_gross
    tax
  }
`;
export const TaskProposalFragmentDoc = gql`
  fragment TaskProposal on TaskProposal {
    id
    task_id
    task {
      identifier
    }
    external_proposal_id
    external_proposal_number
    external_proposal_url
    external_id
    date
    state_id
    note
    reminder
    state {
      id
      title
      color
    }
    external_number
    identifier
    address
    sum_gross
    sum_net
    sum_tax
    items {
      ...TaskProposalItem
    }
    file {
      id
      file_name
      url
    }
    url_preview
  }
  ${TaskProposalItemFragmentDoc}
`;
