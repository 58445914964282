export default {
  labels: {
    identifier: 'Angebots-Nr.',
    externalNumber: 'Schadennummer',
    date: 'Angebotsdatum',
    taskId: 'Auftrag',
    note: 'Notiz',
    address: 'Addresse',
    sumNet: 'Summe Netto',
    sumGross: 'Summe Brutto',
    stateId: 'Status',
    client: 'Kunde',
    activate: 'Angebot aktivieren',
    cancelProposal: 'Angebot stornieren',
    editNote: 'Mahnstatus bearbeiten',
    total: 'Gesamt:',
    clients: 'Versicherungen',
  },
  dialogs: {
    delete: {
      title: 'Angebot löschen?',
      description: 'Sind Sie sicher, dass Sie die Angebot löschen wollen?',
    },
    cancelProposal: {
      title: 'Angebot stornieren?',
      description: 'Sind Sie sicher, dass Sie das Angebot stornieren wollen?',
    },
    invoiceExport: {
      title: 'Angebote exportieren',
      note: 'Laden Sie die exportierten Angebote herunter.',
    },
    editNote: {
      title: 'Mahnstatus',
      note: 'Mahnstatus bearbeiten',
      reminder: 'Mahnung verschickt',
      notifications: {
        error: 'Mahnstatus konnte leider nicht geändert werden.',
        success: 'Mahnstatus erfolgreich geändert.',
      },
    },
    payDate: 'Bezahlt am',
    overdueDate: 'Zahlungsziel',
  },
  pages: {
    overview: {
      title: 'Angebote',
      description:
        'Hier sehen Sie eine Übersicht aller vom System erzeugten Angebote. Sie können über den Aktionen Button die Angebote öffnen.',
    },
  },
};
