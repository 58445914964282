// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": `#a5a5a5`,
	"secondaryColor": `#475467`,
	"tertiaryColor": `#98a2b3`,
	"infoColor": `#2676cc`,
	"successColor": `#027a48`,
	"errorColor": `#d92d20`,
	"warningColor": `#dc6803`,
	"lightColor": `#ebebeb`,
	"darkColor": `#2b303b`,
	"inverseColor": `#2b303b`,
	"xsBreakpoint": `0`,
	"smBreakpoint": `576px`,
	"mdBreakpoint": `768px`,
	"lgBreakpoint": `992px`,
	"xlBreakpoint": `1200px`,
	"xxlBreakpoint": `1400px`
};
export default ___CSS_LOADER_EXPORT___;
